body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-menu {
  background-color: gainsboro;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

table {
  table-layout: fixed;
}

th:first-child {
  width: 5%;
}
th:nth-child(2) {
  width: 23%;
}

th:last-child {
  width: 45%;
}

td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.searchWrapper {
  background-color: white;
  height: 40px;
}

.columns:not(:last-child) {
  margin-bottom: 0;
}

@import "~@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";
@import "~@creativebulma/bulma-badge/dist/bulma-badge.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
